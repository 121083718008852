function Factory () {
  /**
   * GetTestimonials
   * @return {object} testimonials
   */
  this.GetTestimonials = (axios) => {
    const request = axios.get(`${process.env.VUE_APP_URL}testimonial/read.php`)

    return request
  }
}

export default new Factory()
