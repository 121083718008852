import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import Home from '@/pages/Home/Home.vue'
import Projetos from '@/pages/Projetos/Projetos.vue'
import Historia from '@/pages/Historia/Historia.vue'
import Admin from '@/pages/Admin/Admin.vue'
import Login from '@/pages/Login/Login.vue'

Vue.use(Router)

export const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Sejam bem vindos!',
      component: Home
    },
    {
      path: '/projetos',
      name: 'Projetos',
      component: Projetos
    },
    {
      path: '/projetos/:id',
      name: 'Projetos',
      component: Projetos
    },
    {
      path: '/historia',
      name: 'Historia',
      component: Historia
    },
    {
      path: '/admin',
      name: 'Admin',
      component: Admin,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/api/',
      name: 'api',
      component: { template: '<router-view />' }, // Componente vazio para não renderizar nada
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})
