import AdminServices from '@/services/admin.js'
import HomeServices from '@/services/home.js'

export default {
  name: 'Admin',
  components: {},
  data () {
    return {
      testimonial: {
        testimonial: '',
        author: '',
        job: ''
      },
      testimonialSent: false,
      createTestmonial_on: false,
      editTestimonial_on: false,
      testimonialsList: [],
      createJob_on: false,
      job: {
        'name': '',
        'client': '',
        'place': '',
        'description': '',
        'jobTypeId': ''
      },
      jobList: [],
      jobTypeList: [],
      jobSent: false,
      showPhotoSend_on: false,
      file: '',
      photo: {
        'description': '',
        'type_id': '',
        'highlight': 0
      },
      photoSent: false
    }
  },
  methods: {
    createTestmonial: function () {
      this.testimonialSent = false
      const params = {
        'testimonial': this.testimonial.testimonial,
        'author': this.testimonial.author,
        'job': this.testimonial.job
      }
      console.log(params)
      AdminServices.Testimonial(this.$axios, params)
        .then((res) => {
          console.log(res)
          this.testimonialSent = true
          this.resetTestimonials()
        })
        .catch(err => {
          console.error(err)
        })
    },
    showTestimonials: function () {
      if (this.editTestimonial_on) {
        this.editTestimonial_on = false
      } else {
        this.editTestimonial_on = true
        HomeServices.GetTestimonials(this.$axios)
          .then((res) => {
            console.log('res', res)
            this.testimonialsList = res.data.records
          })
      }
    },
    viewEditTestimonial: function (testimonial) {
      this.$set(testimonial, 'updated', false)
      if (testimonial.show) {
        this.$set(testimonial, 'show', false)
      } else {
        this.$set(testimonial, 'show', true)
      }
      console.log('t', testimonial)
    },
    editTestmonial: function (testimonial) {
      console.log('edit', testimonial)
      const params = {
        id: testimonial.id,
        testimonial: testimonial.testimonial,
        author: testimonial.author,
        job: testimonial.job
      }
      AdminServices.UpdateTestimonial(this.$axios, params)
        .then((res) => {
          this.$set(testimonial, 'updated', true)
        })
        .catch(err => console.error('Erro', err))
    },
    confirmDelete: function (testimonial, index) {
      const okTogo = confirm(`Deseja realmente excluir o depoimento de ${testimonial.author} ?`)
      if (okTogo) {
        this.deleteTestimonial(testimonial, index)
      }
    },
    deleteTestimonial: function (testimonial, index) {
      console.log('id', testimonial)
      AdminServices.DeleteTestimonial(this.$axios, {'id': testimonial.id})
        .then((res) => {
          this.testimonialsList.splice(index, 1)
        })
        .catch(err => console.error('Erro', err))
    },
    showCreateJob: function () {
      if (this.createJob_on) {
        this.createJob_on = false
      } else {
        this.readPhotoTypes()
        this.createJob_on = true
      }
    },
    createJob: function () {
      this.jobSent = false
      const params = {
        'name': this.job.name,
        'client': this.job.client,
        'place': this.job.place,
        'description': this.job.description,
        'job_type_id': this.job.type_id
      }
      AdminServices.Job(this.$axios, params)
        .then((res) => {
          console.log(res)
          this.jobSent = true
          // this.resetTestimonials()
        })
        .catch(err => {
          console.error(err)
        })
    },
    readJobs: function () {
      AdminServices.ReadJobs(this.$axios)
        .then(res => {
          this.jobList = res.data.jobs
        })
    },
    readPhotoTypes: function () {
      AdminServices.ReadPhotoType(this.$axios)
        .then((res) => {
          this.jobTypeList = res.data.records
        })
    },
    showPhotoSend: function () {
      if (this.showPhotoSend_on) {
        this.showPhotoSend_on = false
      } else {
        this.showPhotoSend_on = true
        this.readJobs()
      }
    },
    processFile: function (event) {
      this.file = event.target.files[0]
      console.log('file', this.file)
    },
    photoUpload: function () {
      console.log(this.photo)
      let formdata = new FormData()
      formdata.append('photo', this.file)
      formdata.append('description', this.photo.description)
      formdata.append('job_id', this.photo.job_id)
      formdata.append('highlight', this.photo.highlight)
      console.log('formdata', formdata)
      AdminServices.CreatePhoto(this.$axios, formdata)
        .then(() => {
          this.photoSent = true
          this.resetPhotoJob()
        })
        .catch(err => console.error('erro', err))
    },
    resetTestimonials: function () {
      this.testimonial.testimonial = ''
      this.testimonial.author = ''
      this.testimonial.job = ''
      setTimeout(() => { this.testimonialSent = false }, 3000)
    },
    resetPhotoJob: function () {
      this.photo.description = ''
      this.photo.type_id = ''
      document.querySelector('#job_photo_file').value = ''
      setTimeout(() => { this.photoSent = false }, 3000)
    }
  }
}
