function Factory () {
  /**
   * Read Projects
   * @param {axios} axios
   * @return {object} project
   */
  this.GetProjects = (axios) => {
    const request = axios.get(`${process.env.VUE_APP_URL}job/read.php`)

    return request
  }

  /**
   * Read One Project
   * @param {axios} axios
   * @param {id} project id
   * @return {object} project
   */
  this.GetProjectDetail = (axios, id) => {
    const request = axios.get(`${process.env.VUE_APP_URL}job/read_one.php?id=${id}`)

    return request
  }

  /**
   * Read One Project with highight photo
   * @param {axios} axios
   * @param {id} project id
   * @return {object} project
   */
  this.GetProjectDetailHighlight = (axios, id) => {
    const request = axios.get(`${process.env.VUE_APP_URL}job/read_one_photo.php?id=${id}`)

    return request
  }

}

export default new Factory()
