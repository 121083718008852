function Factory () {
  /**
   * Login
   * @param {axios} actions
   * @return {object} user
   */
  this.Login = (axios, user) => {
    const request = axios.post(`${process.env.VUE_APP_URL}user/login.php`, user)

    return request
  }

  /**
   * Testimonial
   * @param {axios} actions
   * @return {object} params
   */
  this.Testimonial = (axios, params) => {
    const request = axios.post(`${process.env.VUE_APP_URL}testimonial/create.php`, params)

    return request
  }

  /**
   * Update Testimonial
   * @param {axios} testimonial
   * @return {object} params
   */
  this.UpdateTestimonial = (axios, file, params) => {
    const request = axios.post(`${process.env.VUE_APP_URL}testimonial/update.php`, file, params)

    return request
  }

  /**
   * Delete Testimonial
   * @param {axios} axios
   * @param {id} number
   * @return {object} params
   */
  this.DeleteTestimonial = (axios, id) => {
    const request = axios.post(`${process.env.VUE_APP_URL}testimonial/delete.php`, id)

    return request
  }

  /**
   * Create job
   * @param {axios} params
   * @param {job} object
   * @return {object} job
   */
  this.Job = (axios, params) => {
    const request = axios.post(`${process.env.VUE_APP_URL}job/create.php`, params)

    return request
  }

  /**
   * Read jobs
   * @param {axios} axios
   * @return {object} jobs
   */
  this.ReadJobs = (axios) => {
    const request = axios.get(`${process.env.VUE_APP_URL}job/list.php`)

    return request
  }
  /**
   * Read job and dependencies
   * @param {axios} axios
   * @param {id} job_id
   * @return {object} jobs
   */
  this.ReadOneJob = (axios, id) => {
    const request = axios.get(`${process.env.VUE_APP_URL}job/read_one.php?id=${id}`)

    return request
  }
  /**
   * Create photo
   * @param {axios} params
   * @return {object} params
   */
  this.CreatePhoto = (axios, file) => {
    console.log('params', file)
    const request = axios.post(`${process.env.VUE_APP_URL}job_photos/create.php`, file)

    return request
  }

  /**
   * Read photo type
   * @param {axios} params
   * @return {object} photo type
   */
  this.ReadPhotoType = (axios) => {
    const request = axios.get(`${process.env.VUE_APP_URL}job_type/read.php`)

    return request
  }
  // /**
  //  * Save a new tweet
  //  * @param {axios} actions
  //  * @param {object} tweet message
  //  * @return {object} request
  //  */
  // this.PostTweet = (axios, params) => {
  //   const request = axios.post(`https://www.talkei.net/store`, params)

  //   return request
  // }
  // /**
  //  * Save a tweet click
  //  * @param {axios} actions
  //  * @param {object} tweet id
  //  * @return {object} request
  //  */
  // this.CountTweet = (axios, id) => {
  //   const request = axios.get(`https://www.talkei.net/counter?id=${id}`)

  //   return request
  // }
}

export default new Factory()
