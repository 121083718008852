import HomeServices from '@/services/home.js'
import ProjectServices from '@/services/projects.js';
import Testimonials from '@/assets/mocks/testimonials.json'
import Projects from '@/assets/mocks/projects.json'

export default {

  data() {
    return {
      modalOn: false,
      modalDesc: '',
      modalImg: '',
      testimonials: {},
      projectList: [],
      projectDetail: {}
    }
  },
  methods: {
    loadModalImg (event) {
      this.modalImg = event.path[0].src;
      this.modalOn = true;
    },
    getTestimonials: function () {
      console.log('ENVV', process.env.NODE_ENV)
      console.log('ENVV', process.env.VUE_APP_URL)
      HomeServices.GetTestimonials(this.$axios)
        .then((res) => {
          this.testimonials = res.data.records;
        })

      // if (process.env.NODE_ENV === 'production') {
      // } else {
      //   this.testimonials = Testimonials.records
      // }
    },
    projectDetails: function (id) {
      ProjectServices.GetProjectDetail(this.$axios, id)
        .then(res => {
          this.project = res.data
          this.sliderSpecs.width = this.project.photos.length * this.sliderSpecs.param
          this.sliderSpecs.left = 0;
          this.projectDetail = id
          this.$router.push({ path: `/projetos/${id}` })
        })
    },
    getProjects: function () {
      var projects = []
        ProjectServices.GetProjects(this.$axios)
          .then(res => {
            
            if (res.data) {
              if (res.data.jobs.length > 1) {
                projects.push(res.data.jobs[res.data.jobs.length - 2])
              }
              projects.push(res.data.jobs[res.data.jobs.length - 1])
              this.projectList = projects;
              
              this.projectList.forEach(p => {
                let date = p.date.split(' ')
                p.date = date[0].replace(/-/g, '/')
              })
            }
          })
          .catch(err => console.error('error ao ler projetos', err))
      // if (process.env.NODE_ENV === 'production') {

      // } else {
      //   projects.push(Projects.jobs[Projects.jobs.length - 2])
      //   projects.push(Projects.jobs[Projects.jobs.length - 1])
      //   this.projectList = projects;
      //   this.projectList.forEach(p => {
      //     let date = p.date.split(' ')
      //     p.date = date[0].replace(/-/g, '/')
      //   })
      // }
    },
    projectDetails: function (id) {
      ProjectServices.GetProjectDetailHighlight(this.$axios, id)
        .then(res => {
          this.projectDetail = res.data
        })
    }
  },
  created() {
    this.getTestimonials();
    this.getProjects();
  },
}
