<template>
  <div id="app">
    <header class="pagetop">
      <div class="pagetop__page-logo">
        <div class="logo">
          <router-link to="/">
            <img src="../src/assets/logo.svg" alt="Jessica Saka - designer de interiores" class="signo">
            <p><img class="jessicasaka" src="../src/assets/jessicasaka.svg" alt="Jessica Saka"> <span> designer de interiores</span></p>
          </router-link>
        </div>
      </div>
      <button class="mobo_menu" @click="mobomenu = !mobomenu">
        <i class="icon-menu"></i>
      </button>
      <nav :class="[mobomenu ? 'mobomenu' : '' ,'pagetop__main-nav']">
        <ul>
          <li @click="mobomenu = false">
            <router-link to="/historia">
              História
            </router-link>
          </li>
          <li @click="mobomenu = false">
            <router-link to="/projetos">
              Projetos
            </router-link>
          </li>
        </ul>
      </nav>
      <section class="pagetop__page-info">

        <button v-if="isLoggedIn" @click="logout()" class="logout buttonreg">
          Sair
        </button>
      </section>
    </header>
    <div class="maincontent">
      <router-view/>
      <footer class="main-footer" id="contato">
        <div class="side contact-info">
          <h4>
            está esperando o que?<br>
            <b>chame a gente</b> pra bater um papo!
          </h4>
          <section>
            <p>
              Tel: <a href="tel:+5511983124175" class="sq inbl">(11) 98312-4175</a>
            </p>
            <p>
              contato@jessicasaka.com.br
            </p>
            <p>
              SANTO ANDRÉ, SP
            </p>
            <ul class="social">
              <!-- <li class="social-facebook">
                <a href="http://www.facebook.com/dolcedesigninteriores" target="_blank">
                  <i class="icon-facebook" />
                  <span class="hide">facebook</span>
                </a>
              </li> -->
              <li class="social-instagram">
                <a href="https://www.instagram.com/jessicasaka.interiores/" target="_blank">
                  <i class="icon-instagram" />
                  <span class="hide">instagram</span>
                </a>
              </li>
              <li class="social-pinterest">
                <a href="https://www.pinterest.pt/jessicasaka_interiores/" target="_blank">
                  <i class="icon-pinterest" />
                  <span class="hide">pinterest</span>
                </a>
              </li>
              <li class="social-whatsapp">
                <a href="https://api.whatsapp.com/send?phone=5511983124175" target="_blank">
                <i class="icon-whatsapp" />
                <span class="hide">+5511983124175</span>
              </a>
              </li>
            </ul>
          </section>
          <p>
            &copy; {{ thisYear }} - Jéssica Saka e VIENA Comunicação.
          </p>
        </div>
        <div class="side contact-form">
          <h4>ENTRE EM CONTATO</h4>
          <form @submit.prevent="sendMail()" class="contact-form">
            <div class="fakefieldset">
              <div class="formside">
                <div class="formP">
                  <label for="name">Nome</label>
                  <input type="text" id="name" placeholder="Nome" v-model="contact.name" required>
                </div>
                <div class="formP">
                  <label for="email">Email</label>
                  <input type="email" id="email" placeholder="Email" v-model="contact.email" required>
                </div>
                <div class="formP">
                  <label for="phone">Telefone</label>
                  <input type="phone" id="phone" placeholder="Telefone" v-model="contact.phone" required>
                </div>
              </div>
              <div class="formside">
                <textarea name="message" id="message" v-model="contact.message" cols="10" rows="10"></textarea>
              </div>
            </div>
            <div class="fakefieldset">
              <div class="formside">
                <span class="loader" v-if="formSent === 'loading'"><i class="icon-loading" /> Enviando...</span>
                <p class="error" v-show="formSent === 'error'"><i class="icon-alert-octagon" /> Um erro ocorreu e sua mensagem não foi enviada.</p>
                <p class="ok" v-show="formSent === 'ok'"><i class=" icon-tick" /> Mensagem enviada com sucesso!</p>
              </div>
              <div class="formside">
                <button type="submit" :disabled="formSent === 'loading'">
                  ENVIAR
                </button>
              </div>
            </div>
          </form>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  data () {
    return {
      mobomenu: false,
      thisYear: new Date().getFullYear(),
      contact: {
        name: '',
        email: '',
        phone: '',
        message: ''
      },
      formSent: ''
    }
  },
  computed : {
    isLoggedIn : function () {
      return this.$store.getters.isLoggedIn
    }
  },
  methods: {
    logout: function () {
      this.$store.dispatch('logout')
        .then(() => {
          this.$router.push('/login')
        })
    },
    sendMail: function () {
      this.formSent = 'loading'
      this.$axios.post(`${process.env.VUE_APP_URL}mail.php`, this.contact)
        .then( res => {

          if (res.status === 200) {
            this.formSent = 'ok'
            this.contact.name = ''
            this.contact.email = ''
            this.contact.phone = '',
            this.contact.message = ''
          } else {
            this.formSent = 'error'
            console.error('Erro ao enviar a mensagem')
          }
        })
        .catch(err => {
          console.error('erro ', err)
        })
    }
  },
  created: function () {
    
    this.$axios.interceptors.response.use(undefined, function (err) {
      return new Promise(function (resolve, reject) {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch(logout)
        }
        throw err;
      });
    });
  }
}
</script>

<style lang="scss" src="../src/assets/sass/base.scss" />
