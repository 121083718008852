import ProjectServices from '@/services/projects.js';
import { router } from '../../router';

export default {
  name: 'Projetos',
  components: {},
  data () {
    return {
      projectList: [],
      projectDetail: '',
      project: {},
      projectId: this.$route.params.id ? this.$route.params.id : 1,
      sliderSpecs: {
        left: 0,
        right: 0,
        width: '',
        param: 98
      }
    }
  },
  methods: {
    getProjects: function () {
      ProjectServices.GetProjects(this.$axios)
        .then(res => {
          this.projectList = res.data.jobs;
        })
        .catch(err => console.error('error ', err))
    },
    projectDetails: function (id) {
      ProjectServices.GetProjectDetail(this.$axios, id)
        .then(res => {
          this.project = res.data
          this.sliderSpecs.width = this.project.photos.length * this.sliderSpecs.param
          this.sliderSpecs.left = 0;
          this.projectDetail = id
          this.$router.push({ path: `/projetos/${id}`})
        })
    },
    slideIt: function (direction) {
      switch (direction) {
        case 'next':
          if (this.sliderSpecs.left >= this.sliderSpecs.width - this.sliderSpecs.param) {
            this.sliderSpecs.left = 0
          } else {
            this.sliderSpecs.left = this.sliderSpecs.left + this.sliderSpecs.param
          }
          break;

        case 'previous':
          if (this.sliderSpecs.left === 0) {
            this.sliderSpecs.left = this.sliderSpecs.width - this.sliderSpecs.param
          } else {
            this.sliderSpecs.left = this.sliderSpecs.left - this.sliderSpecs.param
          }
      }
    }
  },
  created() {
    this.getProjects()
    this.projectDetails(this.projectId)
    window.scroll(0,0)
  },
}
