import Vue from 'vue'
import App from './App.vue'
import { router } from './router'
import store from './store'
import axios from 'axios'
import VueGtag from "vue-gtag";


Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$env = process.env.VUE_APP_URL

Vue.use(VueGtag, {
  config: { id: "UA-150469936-1" }
});

const accessToken = localStorage.getItem('jwt')

if (accessToken) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = accessToken
}

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
