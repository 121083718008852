export default {
  name: 'Login',
  components: {},
  data () {
    return {
      user: {
        email: '',
        password: ''
      }
    }
  },
  methods: {
    login () {
      const email = this.user.email
      const password = this.user.password

      this.$store.dispatch('login', {email, password})
        .then(() => this.$router.push('admin'))
        .catch(err => {console.log('Erro: ', err)})
      // AdminServices.Login(this.$axios, params)
      //   .then(response => {
      //     // console.log('response 1', response.data)
      //     // console.log('response 2', JSON.stringify(response.data))
      //     localStorage.setItem('user', response.data.user)
      //     localStorage.setItem('jwt', response.data.jwt)

      //     if (localStorage.getItem('jwt') != null) {
      //       this.$emit('loggedIn')
      //       if (!this.$route.params.nextUrl) {
      //         this.$router.push('admin')
      //       }
      //     }
      //   })
      //   .catch(error => {
      //     console.error(error.response)
      //   })
    }
  }
}
